import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layouts'
import Slices from '../components/slices'

// Query for the Page content in Prismic
export const query = graphql`
query Page {
  allPrismicPage{
    edges{
      node{
        id
        uid
        type
        data {
          title {
            html
            text
            raw
          }
          body {
            __typename
            ... on PrismicPageBodyText{
              slice_type
              slice_label
              primary{
                text {
                  html
                  text
                  raw
                }
              }
            }
            ... on PrismicPageBodyQuote{
              slice_type
              slice_label
              primary{
                quote {
                  html
                  text
                  raw
                }
              }
            }
            ... on PrismicPageBodyImageWithCaption{
              slice_type
              slice_label
              primary{
                image {
                  alt
                  copyright
                  url
                }
                caption {
                  html
                  text
                  raw
                }
              }
            }
          }
        }
      }
    }
  }
}
`

// Display the title and content of the Page
const PageBody = ({ page }) => {
  return (
    <div class="pure-g">
      <div class="pure-u-lg-5-5 pure-u-1-1 main">
        {/* Render the edit button */}
        <h1 data-wio-id={ page.id }>
          { page.data.title.text }
        </h1>
        {/* Go through the slices of the page and render the appropiate one */}
        <Slices slices={ page.data.body } />
      </div>
    </div>
  );
}

export default (props) => {
  // Define the Page content returned from Prismic
  const doc = props.data.allPrismicPage.edges.filter(page => page.node.uid === props.pageContext.uid)[0];

  if(!doc) return null;

  return(
    <Layout title={ doc.node.data.title.text }>
      <PageBody page={ doc.node } />
    </Layout>
  )
}
